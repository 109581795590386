@media screen and (max-width: 480px) {
  .calculator{
    flex-direction: column !important;
    justify-content: space-evenly !important;
  }
  input{
    font-size: 1.5rem !important;
  }
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.calculator{
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
}

.script{
  font-size: 1rem;
}

.rounded{
  border-radius: 20px;
  text-align: center;
}

.header a{
  position: inherit;
  left: 10px;
}
.header p{
  position: inherit;
  right: 10px;}

.header a img {
  height: 10vmin;
  pointer-events: none;
}

.header{
  height: 10vh;
  width: 100vw;
  position: absolute;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
  align-items: center;
}


.calcs {
  border: none;
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input{
  font-size: 2rem;
}

li{
  list-style: none;
}